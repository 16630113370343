.pubChemFields {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.pubChemFields.loading {
  display: none;
}

.pubChemFields h1 {
  border-bottom: 2px solid var(--blue);
}

.pubChemFields ul {
  list-style-type: square;
}

.pubChemFields .pictograms {
  margin: 10px 0;
}

.pubChemFields .pictograms .pictogram {
  position: relative;
  display: block;
}

.pubChemFields .pictograms .pictogram .description {
  position: absolute;
  top: 50%;
  left: 80px;
  transform: translateY(-50%);
}

.pubChemFields a {
  text-decoration: none;
}

.pubChemFields .link-icon {
  position: relative;
  top: 2px;
  display: inline-block;
  margin: 0 8px;
}
.pubChemFields .link-icon img {
  width: 16px;
}

/* Tooltip text */
.tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 20px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  /* width: 120px; */
  bottom: 100%;
  left: 100%;
  margin-left: -60px;
  z-index: 10;
}

ul.section-content {
  margin: 0;
  padding: 0;
}

ul.section-content li {
  list-style: none;
  margin: 10px 0;
}

/* Show the tooltip text when you mouse over the tooltip container */
.pictogram:hover .tooltiptext {
  visibility: visible;
}

.oops {
  font-style: italic;
  margin: 10px;
}
