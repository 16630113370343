body {
  margin: 0;
  font-family: "Helvetica", "Roboto", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: rgb(247, 247, 247); */
}

code {
  font-family: Roboto, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input,
button {
  font-family: "Helvetica";
}
