.thematic-view-wrapper {
  position: relative;
  margin-top: 200px;
}

.thematic-view-container {
  position: relative;
  display: flex;
  min-width: 1000px;
  max-width: 50vw;
  margin: 100px auto;
  flex-direction: column;
}

.thematic-view-container .flex-item {
  flex: 1;
}
