.home-container {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  text-align: center;
  color: #fff;
  background: transparent;
}

.home-container .background {
  z-index: 2;
  height: 100%;
  opacity: 1;
  overflow: hidden;
  background-image: url("../../src/assets/stacks.jpg");
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-container .overlay {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.home-container .content-group {
  z-index: 3;
  position: absolute;
  top: 30vh;
  left: 50%;
  transform: translateX(-50%);
}

.home-container .header {
  font-size: 56px;
  font-weight: bold;
  margin-bottom: 20px;
}

.home-container .caption {
  font-size: 22px;
  color: #fff;
  opacity: 0.8;
  margin: 40px auto;
}

/* AUTOCOMPLETE */
.suggestion-item {
  color: #3f3844;
}

.home-container .suggestion-icon {
  margin-right: 8px;
}

.home-container .search-bar-container {
  width: 90%;
  max-width: 700px;
  margin: 50px auto 0;
}

.home-container .search-input-container {
  position: relative;
}

.home-container .search-input,
.home-container .search-input:focus,
.home-container .search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 20px;
  font-size: 20px;
  border-radius: 2px;
  outline: none;
}

.home-container .clear-button,
.home-container .clear-button:active,
.home-container .clear-button:focus {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
}

.home-container .autocomplete-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;
}

.home-container .suggestion-item {
  padding: 10px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.home-container .suggestion-item.active {
  background-color: #fafafa;
}

.home-container .dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.home-container .dropdown-footer-image {
  display: inline-block;
  width: 150px;
}

.home-container .spinner {
  color: #18bc9c;
  font-size: 30px;
}

.home-container .error-message {
  font-size: 24px;
  color: red;
  margin: 5px 0;
}

.home-container .geocode-result-header {
  font-size: 20px;
  color: #222222;
}
