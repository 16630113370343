.graph-container .filter-container,
.thematic-view-wrapper .filter-container {
  z-index: 100;
  position: fixed;
  top: 80px;
  width: 100%;
  left: 0;
  background: #fff;
  border-bottom: 1px solid rgba(32, 33, 36, 0.28);
}
/*  */

.navigation {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 80px;
  line-height: 80px;
  margin: 0;
  padding: 0;
  top: 0;
  color: #fff;
  background: #7d3a8b;
}

.navigation.transparent {
  background: transparent;
}

.navigation .logo {
  position: relative;
  float: right;
  cursor: pointer;
  height: 100%;
  margin: 0 20px;
  font-size: 38px;
  font-weight: bold;
}

.navigation a {
  text-decoration: none;

  color: rgba(255, 255, 255, 0.85);
}

.navigation a:hover {
  color: #fff;
}

.navigation li {
  box-sizing: border-box;
}

.navigation li.active {
  font-weight: 600;
}

.navigation .flex-item {
  flex: 1;
}

.navigation ul {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.navigation li,
.go-home {
  display: inline-block;
  padding: 0 20px;
  height: 100%;
  font-size: 18px;
  box-sizing: border-box;
  cursor: pointer;
}

.footer {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  font-size: 14px;
  /* width: 100%; */
  border-top: 1px solid rgba(32, 33, 36, 0.28);
  border-bottom: 1px solid rgba(32, 33, 36, 0.28);
  background: #f8f8f8;
}

.footer .copyright {
  padding: 0 20px;
  margin: 10px 0;
}
