.about-container {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
}

.about-container .background {
  z-index: 2;
  height: 100%;
  opacity: 1;
  overflow: hidden;
  background-image: url("../../src/assets/oil.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-container .overlay {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.about-container .content {
  /* overflow-y: scroll; */
  max-height: 100%;
  width: 100%;
  color: #3f3844;
  font-size: 24px;
  z-index: 3;
  position: absolute;
  top: 150px;
  width: 70%;
  padding: 40px;
  left: 50%;
  background: rgba(255, 255, 255, 0.9);
  transform: translateX(-50%);
  border-radius: 4px;
}

.about-container a {
  text-decoration: none;
  color: #2d42ff;
}

/* .about-container .content > div {
  margin: 20px 0;
} */

.about-container .credits {
  /* position: relative;
  top: 40px; */
  margin-top: 20px;
  font-size: 16px;
}
