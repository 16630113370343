.map-container {
  --primary-dark: #6a0080;
}

.map-container.loading {
  /* opacity: 0.3; */
}

.map-container .map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.map-container .info-window {
  font-size: 1rem;
}

.map-container .refresh {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 10px 30px;
  margin: 20px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  background: var(--primary);
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: opacity 400ms ease-in-out;
}

.spinner {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
